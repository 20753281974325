import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import { ProductListing } from "../components/product-listing"
import {
  container,
  intro,
  callToAction
} from "./index.module.css"

export const query = graphql`
  query {
    shopifyCollection(handle: { eq: "frontpage" }) {
      products {
        ...ProductCard
      }
    }
  }
`
function Hero () {
  return (
    <div className={container} align="center">
      <h1 className={intro}>Welcome to Broken Window Creations!</h1>
      <p><img src="/logo.svg" alt="Broken Window Creations" width="800"/></p>
    </div>
  )
}

function Hero2 () {
  return (
    <div className={container} align="center">
      <h2 className={callToAction}><strong>Featured Items</strong></h2>
    </div>
  )
}

export default function IndexPage({ data }) {
  return (
    <Layout>
      <Hero />
      <Hero2 />
      <ProductListing products={data?.shopifyCollection?.products} />
    </Layout>
  )
}
